/* eslint-disable react/jsx-no-target-blank */
import {KTIcon,KTSVG} from '../../../helpers'

const SidebarFooter = () => {
  return (
    <>

    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6 mt-2 mb-2' id='kt_app_sidebar_footer'>
      <a

       href="mailto:kalil@digitalfactoryindia.com"
        className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
  
        title='Help & Support'
      >
        <span className='btn-label'>Help & Support</span>
        <KTIcon iconName='send' className='btn-icon fs-2 m-0' />
      </a>



    </div>
</>
  ) 
}

export {SidebarFooter}

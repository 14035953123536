import React, { useState, useEffect, useRef } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { WACHAT_MESSAGES, WACHAT_MESSAGE_SEND } from "../../../auth/core/_requests"
import { Modal } from 'react-bootstrap';
import io from 'socket.io-client'
import ChatDetails from './Chat/ChatDetails';
import ChatTemplate from './Chat/ChatTemplate'
import ChatImage from './Chat/ChatImage'
import PaymentModel from './Chat/PaymentModel'
import ContactAdd from './Chat/ContactAdd'
import ChatDocument from './Chat/ChatDocument'
import ChatVideo from './Chat/ChatVideo'
import ChatList, { fetchwaUserData } from './ChatList/ChatList';
import toast from 'react-hot-toast';
const socket = io("https://app.digitalfactoryindia.com:3001");
const successSound = toAbsoluteUrl('/media/audio/outgoing_alert.mp3');


interface WhatsappMessages {
  sender_number: string;
  customer_number: string;
  message: string;
  created_on: string;
  message_media_link: string;
  transaction_type: string;
  message_read: number;
  message_delivered: number;
  message_sent: number;
  message_type: string;
  message_media_type: string;
}

interface CustomersData {
  contact_address: string;
  contact_created_on: string;
  contact_id: string;
  contact_mobile: string;
  contact_name: string;
  contact_email: string;
  contact_tags: [];
}

const Conversation = () => {

  const [waUserMessages, setwaUserMessages] = useState<WhatsappMessages[]>([]);
  const [allowed, setAllowed] = useState<number>()
  const [userDetails, setUserDetails] = useState<CustomersData[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [message, setMessage] = useState<string>('')
  const [messageError, setMessageError] = useState('');
  const [chatuser, setChatUser] = useState("");
  const [loading, setLoading] = useState(true);
  const [buttonisloading, setbuttonisloading] = useState(false);
  const chatMessageRef = useRef<HTMLDivElement | null>(null);
  const [imageModel, setImageModel] = useState(false);
  const [videoModel, setVideoModel] = useState(false);
  const [documentModel, setDocumentModel] = useState(false);
  const [contactModel, setContactModel] = useState(false);
  const [showFullScreenModal, setShowFullScreenModal] = useState(false);
  const [videoFullScreenModal, setVideoFullScreenModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  let prevDate: string | null = null;


  const UserDetails: any = localStorage.getItem('user-details')
  const userDetailsObject = JSON.parse(UserDetails);
  const user_id = userDetailsObject?.user_id
  const customerId = userDetailsObject?.cust_id



  const chatStart = async (wa_id: any) => {
    setChatUser(wa_id);
    // setLoading(true)
    setMessageError('')
    try {
      const response = await WACHAT_MESSAGES(wa_id);
      setwaUserMessages(response.data.wa_messages)
      const socketData = response.data.wa_messages
      socket.emit('WS_OS_DATA', socketData);
      setUserDetails(response.data.wa_cust_details)
      setAllowed(response.data.wa_message_allowed)
      scrollToBottom();
      // setLoading(false)
      // fetchwaUserData("get_all_wa_user", '', '');
    } catch (error) {
      console.error('Error fetching:', error);
    }
  }

  useEffect(() => {
    const fetchData = async (wa_id:any) => {
        if (allowed === 0 ) {
            const lastInMsg = waUserMessages.slice().reverse().find(message => message.transaction_type === 'in');
            if (lastInMsg) {
                const response = await WACHAT_MESSAGES(wa_id);
                setAllowed(response.data.wa_message_allowed);
            } else {
                return;
            }
        }
    };

    fetchData(chatuser);
}, [waUserMessages]);


  socket.on("ws_os_messages", (data: any) => {
    if (data && data.length > 0) {
      setwaUserMessages(data);
    }
  })

  // Use the useEffect hook to scroll to the bottom after waUserMessages changes
  useEffect(() => {
    scrollToBottom();
  }, [waUserMessages]);


  const scrollToBottom = () => {
    if (chatMessageRef.current) {
      chatMessageRef.current.scrollTop = chatMessageRef.current.scrollHeight;
    }
  };

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  const sendMessage = async () => {
    try {
      if (!message) {
        setMessageError('Please type something.')
        return
      } else {
        setMessageError('')
      }
      setbuttonisloading(true);
      const response = await WACHAT_MESSAGE_SEND(chatuser, message, user_id, customerId);
      if (response.data === 'success') {
        chatStart(chatuser);
        setMessage("");
        scrollToBottom();
        setbuttonisloading(false);
        const successAudio = new Audio(successSound);
        successAudio.play();
      } else {
        toast.error("Failed to add task", {
          style: {
            background: '#F13637',
            color: 'white',
          }, duration: 5000
        });
        setbuttonisloading(false);
      }
    } catch (error) {
      console.error('Error fetching:', error);
      setbuttonisloading(false);
    }
  }

  const calculateRemainingTime = () => {
    if (waUserMessages && waUserMessages.length > 0) {
      // Filter waUserMessages to get the last transaction with type 'in'
      const lastInTransaction = waUserMessages.slice().reverse().find(message => message.transaction_type === 'in');

      // Check if there's a last 'in' transaction
      if (lastInTransaction) {
        // Parse the created_on timestamp of the last 'in' transaction
        const createdOnParts = lastInTransaction.created_on.split(/[- :]/);
        const day = parseInt(createdOnParts[0], 10);
        const month = parseInt(createdOnParts[1], 10) - 1; // Month is 0-indexed
        const year = parseInt(createdOnParts[2], 10);
        let hour = parseInt(createdOnParts[3], 10);
        const minute = parseInt(createdOnParts[4], 10);
        const isPM = createdOnParts[5].toLowerCase() === 'pm';

        // Adjust hour if it's PM and not already in 12-hour format
        if (isPM && hour < 12) {
          hour += 12;
        }

        // Create a new Date object
        const lastInTransactionTime: any = new Date(year, month, day, hour, minute);
        // console.log("lastInTransactionTime:", lastInTransactionTime);

        // Get the current time
        const currentTime: any = new Date();
        // console.log("currentTime:", currentTime);

        // Calculate the difference in milliseconds between current time and last transaction time
        const timeDifference = currentTime - lastInTransactionTime;
        // console.log("timeDifference:", timeDifference);

        // Calculate the remaining time in hours
        const remainingHours = 24 - Math.floor(timeDifference / (1000 * 60 * 60));
        // console.log("remainingHours:", remainingHours);

        // Define the width of the progress bar
        const progressPercentage = (remainingHours / 24) * 100;
        return (
          <>
            {remainingHours > 0 ? (
              <div className="progress-circle-container">
                <div className="progress-circle">
                  <div className="progress-circle-inner" style={{ transform: `rotate(${progressPercentage * 3.6}deg)` }}></div>
                </div>
                <span className="progress-circle-label">{`${remainingHours} h`}</span>
              </div>
            ) : (
              <>
                <div className='symbol symbol-30px symbol-circle mb-2 me-3'>
                  <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/stopwatch.png')} />
                </div>
              </>
            )}
          </>
        );
      } else {
        return
      }
    } else {
      // Handle the case when waUserMessages is null or empty
      return null; // or any default value or behavior you prefer
    }
  };

  const handleTemplateOpen = () => {
    setOpenModal(true);
  };

  const handlePaymentModel = () => {
    setPaymentModal(true);
  };

  const handleImageModel = () => {
    setImageModel(true);
  };

  const handleVideoModel = () => {
    setVideoModel(true);
  };

  const handleDocumentModel = () => {
    setDocumentModel(true);
  };

  const handleContactModel = () => {
    setContactModel(true);
  };

  const openFullScreenModal = (image: any) => {
    setSelectedImage(image);
    setShowFullScreenModal(true);
  };

  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const openVideoModal = (video: any) => {
    if (isPlaying) {
      setSelectedVideo(video);
      setVideoFullScreenModal(true);
      setIsPlaying(false); // Set to false here since you're opening the modal
    } else {
      setSelectedVideo(video);
      setVideoFullScreenModal(true);
      setIsPlaying(true); // Set to true here since you're playing the video
    }
  };

  const handleCloseVideo = () => {
    setVideoFullScreenModal(false);
    if (isPlaying) {
      setIsPlaying(false); // Set to false only if the video was playing
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  };

  const getFileName = (url: any) => {
    const parts = url.split('/');
    return parts[parts.length - 1];
  };

  const openFullScreen = (mediaLink: any) => {
    window.open(mediaLink, '_blank', 'fullscreen=yes');
  };


  return (
    <>
      <div className='row'>
        <div className='col-md-4'>
          <ChatList handleContactModel={handleContactModel} chatStart={chatStart} chatuser={chatuser} />
        </div>

        <div className='col-md-8 p-0'>
          {chatuser !== "" ? (
            <div className='row'>
              <div className='col-md-8 pe-2'>
                <div className='card shadow-none flex-column flex-lg-row-auto' style={{ height: "82vh", background: '#f9f2f2' }} id='kt_chat_messenger'>
                  <div className='card-header bg-white' id='kt_chat_messenger_header'>
                    <div className='card-title'>
                      <div className='symbol symbol-30px symbol-circle mb-2 me-3'>
                        <img alt='Pic' src={toAbsoluteUrl('/media/avatars/blank.png')} />
                      </div>
                      <div className='d-flex justify-content-center flex-column me-2'>
                        <span className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1  lh-1'>
                          {chatuser}     <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                        </span>
                      </div>
                    </div>
                    <div className='card-toolbar'>
                      {calculateRemainingTime()}
                    </div>
                  </div>
                  <div className='card-body'>
                    {/* {loading ? (
                      <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                        <div className="spinner-border text-primary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : ( */}
                      <div className='me-n5 pe-5' ref={chatMessageRef} style={{ overflowY: "scroll", height: allowed === 1 ? "50vh" : "45vh" }}>
                        {waUserMessages && waUserMessages.map((wa_data, index) => {
                          const currentDate = wa_data.created_on.split(' ')[0]; // Extract date part
                          const showMessageDate = currentDate !== prevDate;
                          prevDate = currentDate;
                          return (
                            <div key={index} className={`d-flex flex-column ${wa_data.transaction_type === 'in' ? 'message-in' : 'message-out'}`}>
                              {showMessageDate && (
                                <div className='mb-1 mt-1 row align-items-center justify-content-end'>
                                  <div className='col text-center'>
                                    <p className='text-dark badge badge-light-info fs-7 mt-5' >{wa_data.created_on.substring(0, 11)}</p>
                                  </div>
                                </div>
                              )}
                              {wa_data.transaction_type === 'in' && wa_data.message !== "" ? (
                                <>
                                  <div className='me-auto mb-2 align-items-top me-2'>
                                    <div className='p-3 rounded bg-white text-dark text-start mb-1'>
                                      <div dangerouslySetInnerHTML={{ __html: wa_data.message }} style={{ whiteSpace: 'pre-wrap' }} />
                                      <span className='text-muted fs-7 mb-1 mt-1'>{wa_data.created_on.substring(11)}</span>
                                    </div> 
                                  </div>
                                </>
                              ) : (
                                <div className='ms-auto mb-2 align-items-top me-2'>
                                  {wa_data.message_media_type == 'image' || wa_data.message_media_type == 'document' || wa_data.message_media_type == 'video' ? (
                                    <div className='bg-white p-2 w-250px'>
                                      {wa_data.message_media_type === 'image' && (
                                        <img src={wa_data.message_media_link} className='w-100' onClick={() => openFullScreenModal(wa_data.message_media_link)} />
                                      )}

                                      {wa_data.message_media_type === 'document' && (
                                        <div style={{ overflowX: 'hidden' }}>
                                          {wa_data.message_media_link.endsWith('.pdf') && (
                                            <>
                                              <iframe src={wa_data.message_media_link} title="PDF Document" style={{ border: 'none', height: '75px' }} />
                                            </>
                                          )}
                                          {wa_data.message_media_link.endsWith('.doc') && (
                                            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(wa_data.message_media_link)}`} style={{ border: 'none', height: '75px', width: '100%' }} />
                                          )}
                                          {wa_data.message_media_link.endsWith('.docx') && (
                                            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(wa_data.message_media_link)}`} style={{ border: 'none', height: '75px', width: '100%' }} />
                                          )}
                                          <div className='d-flex p-3 cursor-pointer' style={{ backgroundColor: "whitesmoke" }} onClick={() => openFullScreen(wa_data.message_media_link)}>
                                            {wa_data.message_media_link.endsWith('.pdf') && (
                                              <div className='symbol symbol-30px me-3'>
                                                <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/pdf_chat.png')} />
                                              </div>
                                            )}
                                            {wa_data.message_media_link.endsWith('.doc') || wa_data.message_media_link.endsWith('.docx') ? (
                                              <div className='symbol symbol-30px me-3'>
                                                <img alt='Pic' src={toAbsoluteUrl('/media/avatars/file.png')} />
                                              </div>
                                            ) : ''}
                                            <div className='d-flex align-items-center'>{getFileName(wa_data.message_media_link)}</div>
                                          </div>
                                        </div>
                                      )}

                                      {wa_data.message_media_type === 'video' && (
                                        <div className="video-container">
                                          <video src={wa_data.message_media_link} ref={videoRef} className='custom-video' controls={false} />
                                          <div className={`play-pause-button ${isPlaying ? 'paused' : 'playing'}`} onClick={() => openVideoModal(wa_data.message_media_link)}></div>
                                        </div>
                                      )}

                                      <div className='p-3 rounded text-start mw-lg-400px'>
                                        <div dangerouslySetInnerHTML={{ __html: wa_data.message }} style={{ whiteSpace: 'pre-wrap' }} />
                                      </div>
                                      <div className='template_message_preview_time'>
                                        {wa_data.created_on.substring(11)}
                                        {wa_data.message_sent == 1 && wa_data.message_delivered == 0 && wa_data.message_read == 0 ? (<i className="bi bi-check2 fs-5"></i>) : ''}
                                        {wa_data.message_delivered == 1 && wa_data.message_sent == 1 && wa_data.message_read == 0 ? (<i className="bi bi-check2-all fs-5"></i>) : ''}
                                        {wa_data.message_sent == 1 && wa_data.message_delivered == 1 && wa_data.message_read == 1 ? (<i className="bi bi-check2-all text-info fs-5"></i>) : ''}
                                        {wa_data.message_sent == 1 && wa_data.message_read == 1 && wa_data.message_delivered == 0 ? (<i className="bi bi-check2-all text-info fs-5"></i>) : ''}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className='p-3 rounded text-light mw-lg-400px text-dark text-start mt-2' style={{ background: "#E1FFC7" }}>
                                      <div dangerouslySetInnerHTML={{ __html: wa_data.message }} style={{ whiteSpace: 'pre-wrap' }} />
                                      <div className='template_message_preview_time'>
                                        {wa_data.created_on.substring(11)}
                                        {wa_data.message_sent == 1 && wa_data.message_delivered == 0 && wa_data.message_read == 0 ? (<i className="bi bi-check2 fs-5"></i>) : ''}
                                        {wa_data.message_delivered == 1 && wa_data.message_sent == 1 && wa_data.message_read == 0 ? (<i className="bi bi-check2-all fs-5"></i>) : ''}
                                        {wa_data.message_sent == 1 && wa_data.message_delivered == 1 && wa_data.message_read == 1 ? (<i className="bi bi-check2-all text-info fs-5"></i>) : ''}
                                        {wa_data.message_sent == 1 && wa_data.message_read == 1 && wa_data.message_delivered == 0 ? (<i className="bi bi-check2-all text-info fs-5"></i>) : ''}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    {/* )} */}
                  </div>
                  <div className='card-footer bg-white pb-5'>
                    {allowed === 0 ? (
                      <div className=''>
                        <h4>
                          <KTIcon iconName='information-2' className='fs-2 text-danger pe-2' />Start New Conversation
                        </h4>
                        <span className='fs-5'>
                          As you are outside the 24-hour window, Please initiate the conversation via Whatsapp approved message template
                        </span>
                        <div className='d-flex justify-content-end mt-5'>
                          <button className='btn btn-secondary btn-sm' onClick={handlePaymentModel}>Send payment request</button>
                          <button className='btn btn-primary btn-sm ms-4' onClick={handleTemplateOpen}>Send template</button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className='d-flex flex-stack'>
                          <div className='d-flex align-items-top me-2' style={{ width: '90%' }}>
                            <textarea
                              className='form-control'
                              rows={1}
                              data-kt-element='input'
                              placeholder='Type a message'
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              onKeyDown={onEnterPress}
                            ></textarea>
                          </div>
                          {buttonisloading ? (
                            <button className="btn btn-primary btn-sm ps-10 pe-10" type="button" disabled>
                              <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                              <span className="visually-hidden" role="status">Loading...</span>
                            </button>
                          ) : (
                            <button
                              className='btn btn-primary btn-sm align-items-top'
                              type='button'
                              data-kt-element='send'
                              onClick={sendMessage}
                              style={{ width: '15%' }}>
                              <div className='button-text'>
                                Send
                              </div>
                            </button>
                          )}
                        </div>
                        {messageError && <div className="text-danger mt-2">{messageError}</div>}
                        <div className="dropdown dropup">
                          <div className='pt-3 cursor-pointer' id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            <KTIcon iconName='plus-circle' className='fs-1 text-dark fw-bold' />
                            <i className="bi bi-emoji-smile fs-1 text-dark fw-bold ms-3"></i>
                          </div>
                          <ul className="dropdown-menu cursor-pointer" aria-labelledby="dropdownMenuButton" data-bs-popper="none">
                            <li className='dropdown-item d-flex align-items-center' onClick={handleDocumentModel}>
                              <div className='symbol symbol-25px'>
                                <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/file.png')} />
                              </div>
                              <div className='ps-3'>
                                Document
                              </div>
                            </li>
                            <li className='dropdown-item d-flex align-items-center' onClick={handleImageModel}>
                              <div className='symbol symbol-25px'>
                                <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/gallery.png')} />
                              </div>
                              <div className='ps-3'>
                                Image
                              </div>
                            </li>
                            <li className='dropdown-item d-flex align-items-center' onClick={handleVideoModel}>
                              <div className='symbol symbol-25px'>
                                <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/video_play.png')} />
                              </div>
                              <div className='ps-3'>
                                Video
                              </div>
                            </li>
                            <li className='dropdown-item d-flex align-items-center' onClick={handleTemplateOpen}>
                              <div className='symbol symbol-25px'>
                                <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/template.png')} />
                              </div>
                              <div className='ps-3'>
                                Template
                              </div>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-md-4 ps-1'>
                <ChatDetails chatuser={chatuser} userDetails={userDetails} chatStart={chatStart} />
              </div>
            </div>
          ) : (
            <div style={{ background: '#ffe7d9', minHeight: "82vh" }} className='d-flex align-items-center justify-content-center'>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <div className='text-center'>
                      <div className='symbol symbol-75px symbol-circle mb-5'>
                        <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/whatsapp.svg')} />
                      </div>
                      <h4>Begin the Conversation</h4>
                      <p className='text-center'>
                        Tap Left to Kickstart the Discussion!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={`modal${openModal ? ' show' : ''}`} role="dialog" style={{ display: openModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-xl modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select template :</h5>
              <button type="button" className="btn-close" onClick={() => setOpenModal(false)} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <ChatTemplate setOpenModal={setOpenModal} chatuser={chatuser} chatStart={chatStart} />
            </div>
          </div>
        </div>
      </div>

      <div className={`modal${paymentModal ? ' show' : ''}`} role="dialog" style={{ display: paymentModal ? 'block' : 'none' }}>
        <PaymentModel setPaymentModal={setPaymentModal} chatuser={chatuser} />
      </div>

      <div className={`modal${imageModel ? ' show' : ''}`} role="dialog" style={{ display: imageModel ? 'block' : 'none' }}>
        <ChatImage chatuser={chatuser} setImageModel={setImageModel} chatStart={chatStart} />
      </div>

      <div className={`modal${videoModel ? ' show' : ''}`} role="dialog" style={{ display: videoModel ? 'block' : 'none' }}>
        <ChatVideo chatuser={chatuser} setVideoModel={setVideoModel} chatStart={chatStart} />
      </div>

      <div className={`modal${documentModel ? ' show' : ''}`} role="dialog" style={{ display: documentModel ? 'block' : 'none' }}>
        <ChatDocument chatuser={chatuser} setDocumentModel={setDocumentModel} chatStart={chatStart} />
      </div>

      <div className={`modal${contactModel ? ' show' : ''}`} role="dialog" style={{ display: contactModel ? 'block' : 'none' }}>
        <ContactAdd chatuser={chatuser} setContactModel={setContactModel} />
      </div>

      <Modal show={showFullScreenModal} centered onHide={() => setShowFullScreenModal(false)} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {selectedImage && (
            <img
              src={typeof selectedImage == 'string' ? selectedImage : URL.createObjectURL(selectedImage)}
              alt='Full Screen Image'
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal show={videoFullScreenModal} centered onHide={handleCloseVideo} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {selectedVideo && (
            <video
              src={typeof selectedVideo == 'string' ? selectedVideo : URL.createObjectURL(selectedVideo)}
              ref={videoRef}
              controls={true}
              disablePictureInPicture
              controlsList="nodownload noplaybackrate"
              autoPlay={isPlaying}
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Conversation
import React, { useState, useEffect } from 'react';
import { DASHBOARD_STATS } from '../../modules/auth/core/_requests'
import { Chart } from './Chart'; 
//import { useAuth } from '../../modules/auth';

import { Link } from 'react-router-dom';
import { KTIcon } from '../../../_metronic/helpers';
import { DashboardStats } from '../Interface';


const Dashboard = () => { 
  const [stats, setStats] = useState<DashboardStats>();
  //const [loading, setLoading] = useState(false)



  const fetchStatsData = async (dashboard_stats: any) => {
    try {
      const response = await DASHBOARD_STATS(dashboard_stats);
      setStats(response.data);
     // setLoading(false)
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchStatsData("0");
  }, []);



  return (
    <>
       <div className='card bg-gray mb-5'>
        <div className='card-header border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Dashboard</span>
          </h3>
          <div className='card-toolbar'>
            <div className="d-flex align-items-center position-relative">
              <input type="date"
                data-kt-ecommerce-product-filter="search"
                className="form-control form-control-solid w-250px"
                placeholder="Search Order Id"
              />
            </div>
          </div>
        </div>
      </div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <Link to={`/total-orders`}>
            <span className={`card bg-primary hoverable card-xl-stretch mb-5 mb-xl-8`}>
              <div className='card-body'>
                <KTIcon iconName='basket' className={`text-white fs-3x ms-n1`} />
                <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>Total Orders</div>
                <div className={`fw-semibold text-white fs-5`}>{stats?.total_orders}</div>
              </div>
            </span>
          </Link>
        </div>

        <div className='col-xl-3'>
          <Link to={`/order-status/3/`}>
            <span className={`card bg-success hoverable card-xl-stretch mb-5 mb-xl-8`}>
              <div className='card-body'>
                <KTIcon iconName='cheque' className={`text-white fs-3x ms-n1`} />
                <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>Orders Completed</div>
                <div className={`fw-semibold text-white fs-5`}>{stats?.completed_orders}</div>
              </div>
            </span>
          </Link>
        </div>

        <div className='col-xl-3'>
          <Link to={`/order-status/0/`}>
            <span className={`card bg-danger hoverable card-xl-stretch mb-5 mb-xl-8`}>
              <div className='card-body'>
                <KTIcon iconName='chart-simple-3' className={`text-white fs-3x ms-n1`} />
                <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>Pending Orders</div>
                <div className={`fw-semibold text-white fs-5`}>{stats?.pending_orders}</div>
              </div>
            </span>
          </Link>
        </div>

        <div className='col-xl-3'>
          <Link to={`/contacts`}>
            <a className={`card bg-info hoverable card-xl-stretch mb-5 mb-xl-8`}>
              <div className='card-body'>
                <KTIcon iconName='chart-pie-simple' className={`text-white fs-3x ms-n1`} />
                <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>Customers</div>
                <div className={`fw-semibold text-white fs-5`}>{stats?.total_customers}</div>
              </div>
            </a>
          </Link>
        </div>
      </div>

      <div className='row g-5 g-xl-8 '> 
        <div className='col-xl-6'>
          <Chart className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
        <div className='col-xl-6'>
          <div className='card card-xl-stretch mb-5 mb-xl-8'>
            <div className='card-header align-items-center border-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='fw-bold text-dark'>Most Orders Items</span>
                <span className="text-muted fw-semibold fs-7">for this Month</span> 
              </h3>
            </div>
            <div className='card-body py-3' style={{ overflowY: 'auto', maxHeight: '350px' }}>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-3'>
                  <thead>
                    <tr>
                      <th className='p-0 min-w-120px'></th>
                      <th className='p-0 min-w-150px'></th>
                      <th className='p-0 min-w-120px'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {stats?.top_order_items?.length ? (
                      stats.top_order_items.map((item:any, index:any) => (
                        <tr key={index}>
                          <td>
                            <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {item.order_item_name.length > 25 ? `${item.order_item_name.substring(0, 25)}...` : item.order_item_name}
                            </div>
                          </td>
                          <td className='text-center'>
                            <span className='text-muted fw-semibold d-block fs-7'>Qty</span>
                            <span className='text-dark fw-bold d-block fs-5'>{item.total_qty}</span>
                          </td>
                          <td>
                            <span className='text-muted fw-semibold d-block fs-7'>Earnings</span>
                            <span className='text-dark fw-bold d-block fs-5'>{item.total_sale}</span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} className="text-center text-muted fw-semibold fs-7 pt-20">
                          No data available
                        </td>
                      </tr>
                    )} 
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default Dashboard
import {useEffect,useState} from 'react'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {Footer} from './Footer'
import {toAbsoluteUrl } from '../../../../_metronic/helpers'
import io from 'socket.io-client'
const socket = io("https://app.digitalfactoryindia.com:3001");


const successSound = toAbsoluteUrl('/media/audio/new_order.mp3');
const messageSound = toAbsoluteUrl('/media/audio/incoming_message.mp3');
const FooterWrapper = () => {
  const [toastAlert, setToastAlert] = useState(false);

  useEffect(() => {
    const Order = 0;

    //success_toast('1');
    // Assuming socket is properly initialized
    socket.emit('WS_NOTIF', Order);
    socket.on('WS_OS_NOTIF_CALL', (Order: any) => {
      //console.log('Socket event received:', data);
      if (Order == 1) {
       // console.log('Before success_toast');
        success_toast();
      //  console.log('After success_toast');
      //  console.log('asdfsadfsadfsad');
      } else {
        console.log('Not Triggered');
      }

    });

    socket.emit('WS_NOTIF_MESSAGE', Order);
    socket.on('WS_OS_NOTIF_MESSAGE_CALL', (Order: any) => {
      //console.log('Socket event received:', data);
      if (Order == 1) {
       // console.log('Before success_toast');
       message_toast();
      //  console.log('After success_toast');
      //  console.log('asdfsadfsadfsad');
      } else {
        console.log('Not Triggered');
      }

    });

  }, []);


const success_toast = () =>
{

  const successAudio = new Audio(successSound);
  successAudio.play()
    .catch(error => {
      console.error('Error playing audio:', error);
    });

  setToastAlert(true);
    // Automatically hide the toast after 2.5 seconds
    setTimeout(() => {
      setToastAlert(false);
    }, 12000); // 2500 milliseconds (2.5 seconds)
}

const message_toast = () =>
{

  const successAudio = new Audio(messageSound);
  successAudio.play()
    .catch(error => {
      console.error('Error playing audio:', error);
    });

}

  const {config} = useLayout()
  if (!config.app?.footer?.display) {
    return null
  }


  return (
    <div className='app-footer' id='kt_app_footer'>
      {config.app.footer.containerClass ? (
        <div className={clsx('app-container', config.app.footer.containerClass)}>
          <Footer />
        </div>
      ) : (
        <Footer />
      )}

<svg xmlns="http://www.w3.org/2000/svg" style={{display: 'none'}}>
  <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
  </symbol>
</svg>

<div id="myToast" className="position-fixed top-0 start-50 translate-middle-x p-2" style={{ display: toastAlert ? 'block' : 'none' }}>
<div className="alert bg-success text-light d-flex align-items-center" role="alert">
  <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:">
    <use xlinkHref="#check-circle-fill"/></svg>
  <div>
   New Order Received
  </div>

</div>

    </div>
    </div>
  )
}

export {FooterWrapper}
